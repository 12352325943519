<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <span>
        <b v-if="id!=null">Edit</b>
        <b v-else>Create</b>
        </span>
        <div class="vx-row mb-12" style="width:100%">
			<div class="vx-col sm:w-1/3 w-full">
				<span>Customer</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <multiselect
                class="selectExample"
                v-model="customerData.selected"
                :options="customerData.options"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                placeholder="Type to search"
                track-by="code"
                label="name"
                >
                <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                    <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                    >
                    </span>
                </template>

                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                    <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                    >
                    </div>
                </template>
                </multiselect>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('Customer')"
                    >{{ errors.first("Customer") }}</span
                >
            </div>
            <br><br><br>
            <div class="vx-col sm:w-1/3 w-full">
				<span>Customer Return Type</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <multiselect
                v-model="salesReturnType.selected"
                :options="salesReturnType.options"
                track-by="Name"
                label="name"
                :max-height="125"
                :show-labels="false"
                v-validate="'required'"
                >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                    <span class="option__title"
                        >{{ props.option.Name }}</span
                    >
                    </span>
                </template>

                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                    <span class="option__title"
                        >{{ props.option.Name }}</span
                    >
                    </div>
                </template>
                </multiselect>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('SalesReturnType')"
                    >{{ errors.first("SalesReturnType") }}</span
                >
            </div>
            <br><br><br>
            <div class="vx-col sm:w-1/3 w-full">
				<span>Note</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <vs-textarea class="w-full" v-model="note" v-validate="'required'"/>
                <span class="text-danger text-sm" v-show="errors.has('Note')">{{errors.first("Note")}}</span>
            </div>
		</div>
        <div class="vx-row mb-6" style="width:100%">
            <div class="vx-col sm:w-5/5 w-full mb-2">
                <div class="vx-row mb-2">
                    <div class="vx-col w-3/5" style="display: flex">
                        <label class="vs-input--label">Item</label>
                    </div>
                    <div class="vx-col w-1/5" style="display: flex">
                        <label class="vs-input--label">Unit</label>
                    </div>
                    <div class="vx-col w-1/5" style="display: flex">
                        <div class="vx-col w-2/3 mr-1">
                            <label class="vs-input--label">Qty Return</label>
                        </div>
                        <div class="vx-col w-1/3 mr-1">
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
                <div class="vx-row mb-2">
                    <div class="vx-col w-3/5" style="display: flex">
                        <multiselect
                        v-model="itemData.selected[indextr]"
                        :options="itemData.options"
                        @input="getUnitData($event.ID, indextr)"
                        track-by="ID"                     
                        :max-height="125"
                        :show-labels="false"
                        label="Name"
                        >
                        <span slot="noResult">Oops! No data found</span>
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                            <span class="option__title"
                                >{{ props.option.SkuCode }} - {{ props.option.Name }}</span
                            >
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                            <span class="option__title"
                                >{{ props.option.SkuCode }} - {{ props.option.Name }}</span
                            >
                            </div>
                        </template>
                        </multiselect>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('Item')"
                            >{{ errors.first("Item") }}</span
                        >
                       
                    </div>
                    <div class="vx-col w-1/5" style="display: flex">
                        <multiselect
                        v-model="unitData[indextr].selected"
                        :options="unitData[indextr].options"
                        track-by="ID"                     
                        :max-height="125"
                        :show-labels="false"
                        >
                        <span slot="noResult">Oops! No data found</span>
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                            <span class="option__title"
                                >{{ props.option.Name }}</span
                            >
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                            <span class="option__title"
                                >{{ props.option.Name }}</span
                            >
                            </div>
                        </template>
                        </multiselect>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('Unit')"
                            >{{ errors.first("Unit") }}</span
                        >
                       
                    </div>
                    <div class="vx-col w-1/5" style="display: flex">
                        
                        <div class="vx-col w-2/3">
                            <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :max="items[indextr].QuantityReturn"
                                    :step="1"
                                    size="small"
                                    v-model="items[indextr].quantityReturn"
                                    :value="0"
                                />
                        </div>
                        <div class="vx-col w-1/3" style="text-align: center; margin: auto; margin-bottom: 0">
                            <vs-button
                                v-if="items[indextr].status=='minus'"
                                style="display: table-cell;"
                                v-on:click="handleRemoveItem(indextr)"
                                color="danger"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-minus"
                            ></vs-button>
                            <vs-button
                                v-else
                                style="display: table-cell;"
                                v-on:click="handleAddItem()"
                                color="success"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-plus"
                            ></vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            
        <div class="vx-row">
        <div class="vx-col w-full">
            <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
        </div>
        </div>

    </div>
</template>
<script>

import VueNumberInput from "@chenfengyuan/vue-number-input";

export default {
    components: {
        VueNumberInput
    },
    props: {
        id: {
        type: Number
        },
        url: {
        type: String
        },
        title: {
        type: String
        }
    },
    mounted(){    
             
        this.getAllDataSalesReturnType();
        this.getCustomerData();
        this.getItemData();
        if (this.id) {
            this.getData();
        }
    },
    watch: {
        id(v) {
            this.getAllDataSalesReturnType();
            this.getCustomerData();
            this.getItemData();
            if (v) {
                this.getData();
            } else {
                Object.assign(this.$data, this.initialState());
            }
        },
        "salesReturnType.selected": function (val) {
            if (val) {
                this.errors.clear();
                this.salesReturnTypeID = val.id ? val.id : val.ID;
            } else {
                this.salesReturnTypeID = null;
            }
        },
        "customerData.selected": function (val) {
            if (val) {
                this.errors.clear();
                this.customerID = val.id ? val.id : val.ID;
            } else {
                this.customerID = null;
            }
        },
    },
    computed: {
        currentPageDetail: {
            get() {
                return 1;
            },
            
        },
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                quantityReturn: [],
                itemID: [],
                unitID: [],
                ItemName : [],
                items: [{
                    num: 0,
                    quantityReturn: 0,
                    status: "plus",
                }],

                detailShow: "core vx-col md:w-1/2 w-full mb-base",
                detailHide: "core vx-col md:w-1/1 w-full mb-base",
                detail: false,
                salesReturnTypeID: null,     
                salesReturnType: {
                    selected: null,
                    options: [],
                },
                note: "",
                customerID: null,     
                customerData: {
                    selected: null,
                    options: [],
                },
                itemData: {
                    selected: [],
                    options: [],
                },
                unitData:[{selected:null,options:[]}]
            };
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!this.customerID) {
                    this.errors.add({
                        field: "Customer",
                        msg: "The Customer field is required",
                    });
                    result = false;
                }
                if (!this.salesReturnTypeID) {
                    this.errors.add({
                        field: "SalesReturnType",
                        msg: "The Customer Return Type field is required",
                    });
                    result = false;
                }
                if (!this.note) {
                    this.errors.add({
                        field: "Note",
                        msg: "The Note field is required",
                    });
                    result = false;
                }
                var _this = this
                this.items.forEach(function(element, index){
                    _this.itemID[index] = _this.itemData.selected[index].ID
                    _this.unitID[index] = _this.unitData[index].selected.ID
                    _this.quantityReturn[index] = _this.items[index].quantityReturn
                })
                //console.log(this.itemData.selected)
                // if (!this.itemData.selected.ID) {
                //     this.errors.add({
                //         field: "Item",
                //         msg: "The Item field is required",
                //     });
                //     result = false;
                // }
                // if (!this.unitData.selected.ID) {
                //     this.errors.add({
                //         field: "Unit",
                //         msg: "The Unit field is required",
                //     });
                //     result = false;
                // }

                if (result) {
                    this.$vs.loading();
                    if (this.id) {
                    this.putData();
                    } else {
                    this.postData();
                    }
                }
            });
        },
        paramData() {
            return {
                quantity_return: this.quantityReturn,
                item_id: this.itemID,
                unit_id: this.unitID,
                customer_id: this.customerID,
                sales_return_type_id: this.salesReturnTypeID,
                note: this.note
            };
        },
        postData() {
        this.$http
            .post(this.url, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Saved",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
            this.$vs.loading.close();
            console.log("error: ", error);
            });
        },  
        putData() {
        this.$vs.loading();
        this.$http
            .put(this.url + "/" + this.id, this.paramData())
            .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Updated",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
                });

                this.$vs.loading.close();
            } else {
                this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
                });
                this.$vs.loading.close();
            }
            })
            .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
            });
        },     
        getData() {
        this.$vs.loading();
        if (this.id) {
            this.$http
            .get(this.url + "/" + this.id)
            .then((resp) => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    this.note = resp.data.sales_return.Note;   

                    if (resp.data.sales_return.CustomerID) {
                        this.setCustomerSelected(resp.data.sales_return.CustomerID);
                    }

                    if (resp.data.sales_return.SalesReturnTypeID) {
                        this.setSalesReturnTypeSelected(resp.data.sales_return.SalesReturnTypeID);
                    }

                    _this.items = [];
                    var statusAdd;

                    for(var k = 0; k < resp.data.sales_return_line.length; k++){
                        _this.unitData.push({selected:null, options:[]})
                        if(k == 0){
                            statusAdd = "plus"
                        }else{
                            statusAdd = "minus"
                        }
                        _this.items.push({
                            quantityReturn: resp.data.sales_return_line[k].Quantity,
                            status: statusAdd,
                            // unitData[k].options: resp.data.sales_return_line[k].Quantity.toString(),
                        }) 
                        
                        if (resp.data.sales_return_line[k].ItemID) {
                            this.setItemSelected(k, resp.data.sales_return_line[k].ItemID);
                        }

                        if (resp.data.sales_return_line[k].UnitID) {
                            this.getUnitData(resp.data.sales_return_line[k].ItemID, k);
                            this.setUnitSelected(k, resp.data.sales_return_line[k].UnitID); 
                        }
                    }
                    


                } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        }
        }, 
        getAllDataSalesReturnType() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/sales-return-type", {
                params: {
                    order: "name",
                    sort: "asc",
                },
                })
                .then((resp) => {
                if (resp.status == "success") {
                    this.salesReturnType.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
        },
        getCustomerData() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/master/customer-company", {
                })
                .then((resp) => {
                if (resp.status == "success") {
                    this.customerData.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
        },
        getItemData() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/master/item", {
                })
                .then((resp) => {
                if (resp.status == "success") {
                    this.itemData.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
        },
        getUnitData(id, indextr) {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/unit-by-item/" + id)
            .then((resp) => {
            if (resp.status == "success") {
                this.unitData[indextr].options = resp.data;
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
            });
        },
        setCustomerSelected(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/" + id).then((resp) => {
                if (resp.status == "success") {
                this.customerData.selected = resp.data;
                this.$vs.loading.close();
                } else {
                this.$vs.loading.close();
                }
            });
        },
        setSalesReturnTypeSelected(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/sales-return-type/" + id).then((resp) => {
                if (resp.status == "success") {
                this.salesReturnType.selected = resp.data;
                this.$vs.loading.close();
                } else {
                this.$vs.loading.close();
                }
            });
        },
        setItemSelected(indextr, id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/item/" + id).then((resp) => {
                if (resp.status == "success") {
                this.itemData.selected[indextr] = resp.data;
                this.$vs.loading.close();
                } else {
                this.$vs.loading.close(); 
                }
            });
        },
        setUnitSelected(indextr, id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/unit-by-id/" + id).then((resp) => {
                if (resp.status == "success") {
                this.unitData[indextr].selected = resp.data;
                this.$vs.loading.close();
                } else {
                this.$vs.loading.close(); 
                }
            });
        },
        handleAddItem(){
            this.unitData.push({selected:null, options:[]})
            this.items.splice(this.items.length+1, 0, {
                num: 0,
                quantityReturn: 0, 
                status: "minus",
            })
        },
        handleRemoveItem(indextr){
            this.items.splice(indextr, 1)
        },
    }
}

</script>